const baseConfiguration = {
    apiPageSize: 25,
    product: 'purecloud',
    regions: [
        {
            name: 'cac1.pure.cloud',
            display: 'Americas (Canada)',
        },
        {
            name: 'sae1.pure.cloud',
            display: 'Americas (São Paulo)'
        },
        {
            name: 'mypurecloud.com',
            display: 'Americas (US East)',
        },
        {
            name: 'use2.us-gov-pure.cloud',
            display: 'Americas (US East 2)',
        },
        {
            name: 'usw2.pure.cloud',
            display: 'Americas (US West)',
        },
        {
            name: 'aps1.pure.cloud',
            display: 'Asia Pacific (Mumbai)',
        },
        {
            name: 'apne2.pure.cloud',
            display: 'Asia Pacific (Seoul)',
        },
        {
            name: 'mypurecloud.com.au',
            display: 'Asia Pacific (Sydney)',
        },
        {
            name: 'mypurecloud.jp',
            display: 'Asia Pacific (Tokyo)',
        },
        {
            name: 'mypurecloud.ie',
            display: 'EMEA (Dublin)',
        },
        {
            name: 'mypurecloud.de',
            display: 'EMEA (Frankfurt)',
        },
        {
            name: 'euw2.pure.cloud',
            display: 'EMEA (London)',
        }
    ],
    client: {
        sandbox: 'bf754d5c-5b0a-4b1c-8217-7dbc80c0cc1d',
        dev: 'bf754d5c-5b0a-4b1c-8217-7dbc80c0cc1d',
        test: 'bf754d5c-5b0a-4b1c-8217-7dbc80c0cc1d',
        production: 'bf754d5c-5b0a-4b1c-8217-7dbc80c0cc1d'
    },
    apiEndpoint: {
        sandbox: 'https://api.sandbox.genesys-ignite.genesyspsdevops-dev.com/rest/api',
        dev: 'https://api.dev.genesys-ignite.genesyspsdevops-dev.com/rest/api',
        test: 'https://api.test.genesys-ignite.genesyspsdevops-dev.com/rest/api',
        production: 'https://api.genesys-ignite.genesyspsdevops.com/rest/api'
    }
}

const development = {
    purecloud: {
        apiPageSize: baseConfiguration.apiPageSize,
        regions: JSON.parse(JSON.stringify(baseConfiguration.regions)),
        client: baseConfiguration.client.dev
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.dev
}

const test = {
    purecloud: {
        apiPageSize: baseConfiguration.apiPageSize,
        regions: JSON.parse(JSON.stringify(baseConfiguration.regions)),
        client: baseConfiguration.client.test
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.test
}

const sandbox = {
    purecloud: {
        apiPageSize: baseConfiguration.apiPageSize,
        regions: JSON.parse(JSON.stringify(baseConfiguration.regions)),
        client: baseConfiguration.client.sandbox
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.sandbox
}

const production = {
    purecloud: {
        apiPageSize: baseConfiguration.apiPageSize,
        regions: JSON.parse(JSON.stringify(baseConfiguration.regions)),
        client: baseConfiguration.client.production
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.production
}

const env = process.env.REACT_APP_CUSTOM_ENV.trim()
console.log('env:', env)
let config
switch (env) {
    case 'production': config = production; break;
    case 'test': config = test; break;
    case 'development': config = development; break;
    case 'sandbox': config = sandbox; break;
    default: break;
}

export default config
export const headerTitle = "Genesys Cloud Ignite";