import './prompts.css'
import React from 'react'
import { FaLink, FaTrash, FaInfoCircle, FaPlus, FaCloudUploadAlt } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';
import DragAndDrop from '../../Misc/dragAndDrop/dragAndDrop';
import { uploadPromptsFile } from '../../../services/misc/zipImport';
import { checkProgress } from '../../../services/purecloud/flows';
import { defaultPrompts, defaultLanguages } from '../prompts/promptsConfig';
import { getSelectedRegion } from '../../../global/localStorage';
import { getToken, getOrgMe, getUserMe, logActionData } from '../../../services/session/session';
import { uploadPrompts, getArchitects } from '../../../services/purecloud/architect';
import PromptsInfo from '../prompts/promptsInfo';
import PreloaderLocal from '../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../Misc/informationMessage/informationMessage';
import BulkDelete from '../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const promptsDataFields = [
    'promptId',
    'description',
    'promptText',
    'promptName',
    'language',
    'promptFile',
    'promptContent',
    'useTTS',
];

export default class Prompts extends React.Component {

    state = {
        env: '',
        token: '',
        orgMe: {},
        userMe: {},
        promptsList: defaultPrompts,
        isPromptsInfoOpen: false,
        promptId: '',
        promptName: '',
        description: '',
        useTTS: false,
        promptText: '',
        promptFile: 'n/a',
        promptContent: null,
        language: 'en-us',
        rowId: null,
        architectPromptsList: [],
        languagesList: defaultLanguages,
        isSaveLocale: false,
        preloaderLocalShow: false,
        preloaderLocalMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    async componentDidMount() {
        await this.loadOrg();
        await this.loadItems();
        await this.validateItems();
    }

    loadOrg = async () => {
        try {
            const orgMe = await getOrgMe();
            const userMe = await getUserMe();
            await this.setState({ orgMe: orgMe, userMe: userMe });
        } catch (error) {
            throw error;
        }
    }

    loadItems = async () => {
        await this.setState({ preloaderLocalShow: true, preloaderLocalMessage: 'Loading Prompts' });
        this.state.architectPromptsList = await getArchitects(this.state.env, this.state.token, 'prompts');
        await this.setState({ preloaderLocalShow: false, preloaderLocalMessage: 'Loading' });
    }

    validateItems = async () => {
        await this.setState({ preloaderLocalShow: true, preloaderLocalMessage: 'Validating Default Items' });
        let promptsList = this.state.promptsList;
        for (const i in promptsList) {
            const prompt = promptsList[i];
            await this.updatePrompts(promptsList, prompt.promptName);
        }
        await this.setState({ preloaderLocalShow: false, preloaderLocalMessage: 'Loading', promptsList: promptsList });
    }

    getPromptId = (name) => {
        const prompt = this.state.architectPromptsList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (prompt) { return prompt.id; }
    }

    updatePrompts = async (myArray, name) => {
        const promptId = this.getPromptId(name);
        if (promptId) {
            const objIndex = myArray.findIndex((obj => obj.promptName == name));
            myArray[objIndex].promptId = promptId;
        }
    }

    removeRowClicked = async (rowId, e) => {
        if (e) {
            e.stopPropagation();
        }
        var promptsList = [...this.state.promptsList];
        var selectedList = this.state.selected;
        for (const i in promptsList) {
            if (promptsList[i].rowId === rowId) {
                promptsList.splice(i, 1);
                selectedList[rowId] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }   
        await this.setState({ promptsList: promptsList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isPromptsInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            promptName: '',
            description: '',
            promptId: '',
            useTTS: false,
            promptText: '',
            promptFile: 'n/a',
            promptContent: null,
            language: 'en-us',
            rowId: null,
        })
    }

    handleSubmitFunction = () => {
        if (this.validatePromptName()) {
            let promptsList = [...this.state.promptsList]
            var newRow = { 'rowId': uuidv1() };
            for (const i in promptsDataFields) {
                if (promptsDataFields[i] === 'promptId') {
                    newRow[promptsDataFields[i]] = this.state.promptId;
                } else if (promptsDataFields[i] === 'description') {
                    newRow[promptsDataFields[i]] = this.state.description;
                } else if (promptsDataFields[i] === 'promptText') {
                    newRow[promptsDataFields[i]] = this.state.promptText;
                } else if (promptsDataFields[i] === 'promptName') {
                    newRow[promptsDataFields[i]] = this.state.promptName;
                } else if (promptsDataFields[i] === 'language') {
                    newRow[promptsDataFields[i]] = this.state.language;
                } else if (promptsDataFields[i] === 'promptFile') {
                    newRow[promptsDataFields[i]] = this.state.promptFile;
                } else if (promptsDataFields[i] === 'promptContent') {
                    newRow[promptsDataFields[i]] = this.state.promptContent;
                } else if (promptsDataFields[i] === 'useTTS') {
                    newRow[promptsDataFields[i]] = this.state.useTTS;
                }
            }

            promptsList.push(newRow);

            this.setState({
                isPromptsInfoOpen: false,
                promptsList: promptsList,
            });
        } else {
            this.eventList = [];
            this.logEvent('Prompt name can only contain letters, numbers and underscores. They must start with a letter', true);
            this.showEventList(this.eventList);
        }
    }

    validatePromptName = () => {
        let isValid = false;
        const validateWholeword = /^[0-9A-Za-z\_]+$/;
        const validateFirstLetter = /^[A-Za-z]+$/;
        isValid = validateFirstLetter.test(this.state.promptName[0]) && validateWholeword.test(this.state.promptName);
        return isValid;
    }

    handleInputChange = (fieldName) => (event) => {
        if (fieldName === 'useTTS') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked,
            });
        } else {
            this.setState({
                [fieldName]: event.target.value
            });
        }
    }

    updateState = (rowInfo) => {
        this.setState({
            promptName: rowInfo.promptName,
            description: rowInfo.description,
            promptId: rowInfo.promptId,
            useTTS: rowInfo.useTTS,
            promptText: rowInfo.promptText,
            promptFile: rowInfo.promptFile,
            promptContent: rowInfo.promptContent,
            language: rowInfo.language,
            rowId: rowInfo.rowId,
            isPromptsInfoOpen: true,
            isSaveLocale: true,
        });
    }

    updateFunction = () => {
        if (this.validatePromptName()) {
            let promptsList = [...this.state.promptsList];
            for (let did of promptsList) {
                if (did.rowId === this.state.rowId) {
                    did.promptName = this.state.promptName;
                    did.promptId = this.state.promptId;
                    did.description = this.state.description;
                    did.useTTS = this.state.useTTS;
                    did.promptText = this.state.promptText;
                    did.promptFile = this.state.promptFile;
                    did.promptContent = this.state.promptContent;
                    did.language = this.state.language;
                }
            }

            this.setState({
                promptsList: promptsList,
                isPromptsInfoOpen: false,
            })
        } else {
            this.eventList = [];
            this.logEvent('Prompt name can only contain letters, numbers and underscores. They must start with a letter', true);
            this.showEventList(this.eventList);
        }
    }

    createDocReaderPromise = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file,
                };
                resolve(fileInfo.base64.split(',')[1]);
            };
            reader.onerror = () => {
                reject();
            };
        });
    }

    importWav = async (file, fileName) => {
        await this.setState({ preloaderLocalShow: true, promptFile: fileName });
        const fileContent = await this.createDocReaderPromise(file);
        await this.setState({ preloaderLocalShow: false, promptContent: fileContent });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importWav(e.target.files[0], file.name);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.wav')) {
                console.error('Only WAV files are allowed');
                return;
            }
            if (file) {
                this.importWav(file, file.name);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importPrompts = async () => {
        this.eventList = [];
        const promptsList = this.state.promptsList;
        for (const i in promptsList) {
            const promptData = promptsList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing user [${promptData.promptName}]`);
            try {
                let resources = {}; let promptsbody = {};
                resources['language'] = promptData.language;
                resources['promptFile'] = promptData.promptFile;
                resources['promptText'] = promptData.promptText;
                resources['promptContent'] = promptData.promptContent;
                resources['useTTS'] = promptData.useTTS;

                promptsbody['rowId'] = promptData.rowId;
                promptsbody['description'] = promptData.description;
                promptsbody['promptName'] = promptData.promptName;
                promptsbody['promptId'] = promptData.promptId;
                promptsbody['resources'] = [resources];
                console.log(JSON.stringify(promptsbody));

                const response = await uploadPrompts(promptsbody, this.state.token, this.state.env, this.state.orgMe.name, this.state.userMe.email);
                if (response) {
                    if (response.messages.length === 0) {
                        this.logEvent(`User [${promptData.promptName}] successfully processed`);
                        this.removeRowClicked(promptData.rowId);
                    } else {
                        response.messages.map((message) => {
                            this.logEvent(message.content, true);
                        });

                    }
                }
            } catch (err) {
                this.logEvent(`${err.message}`, true);
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing Prompts`, count, 'Prompts');
        }
        this.showEventList(this.eventList);
        await this.setState({ preloaderLocalShow: false });
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.promptsList.forEach(x => {
                newSelected[x.rowId] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Prompts...." });
        let selected = this.state.selected;
        for (let rowId of Object.keys(selected)) {
            if (selected[rowId] === true) {
                console.log(rowId.replace(/"|'/g, ''))

                let a = rowId.replace(/"|'/g, '');
                this.removeRowClicked(a);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
    }

    handleZipDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.zip')) {
                console.error('Only ZIP files are allowed');
                return;
            }
            if (file) {
                this.importZip(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleZipImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importZip(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importZip = async (file) => {
        await this.setState({ preloaderLocalShow: true, preloaderLocalMessage: 'Importing Bulk Prompts' });
        this.logEvent(`Zip file import started`);
        this.eventList = [];
        try {
            const encodedFileContent = await this.createDocReaderPromise(file);
            console.log(encodedFileContent);
            const token = await uploadPromptsFile(file.name, encodedFileContent, this.state.userMe.email, this.state.orgMe.name, this.state.env, this.state.token);
            if (token) {
                let response = await this.callFlowProgress(token);
                console.log(response);
                if (response) {
                    if (response.importStatus === 'SUCCESS') {
                        await logActionData('Import', `Importing Prompts`, response.count, 'Prompts');
                        this.logEvent(`Import of ${response.count} ${response.objectType} is successful`, false);
                    } else if (response.importStatus === 'ERROR') {
                        response.failedRows.forEach(failedRows => {
                            this.logEvent(`Import failed with Error Message - " ${failedRows.message[0].content}"`, true)
                        });
                    } else {
                        this.logEvent(`Import of Prompts Failed - ${response}`, true)
                    }
                }
            }
        }
        catch (err) {
            console.log(err);
            this.logEvent(`${err.message}`, true);
        }
        this.showEventList(this.eventList);
        await this.setState({ preloaderLocalShow: false, preloaderLocalMessage: 'Importing Bulk Prompts' });
    }

    callFlowProgress = async (token) => {
        if (token) {
            while (true) {
                let response = await checkProgress(this.state.userMe.email, this.state.orgMe.name, token);
                if (response.progress !== undefined) {
                    await this.setState({ preloaderLocalShow: true, preloaderLocalMessage: 'Importing Prompts - ' + response.progress + '% ' });
                    await this.sleep(3000);
                } else {
                    await this.setState({ preloaderLocalShow: false, preloaderLocalMessage: 'Importing Prompts - ' + '100% ' });
                    return response;
                }
            }
        }
    }

    render() {
        let MAP = {
            name: "p-map",
            areas: []
        }
        return (
            <div className="promptsCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <PromptsInfo
                    title={this.props.cardTitle}
                    isOpen={this.state.isPromptsInfoOpen}
                    promptName={this.state.promptName}
                    description={this.state.description}
                    useTTS={this.state.useTTS}
                    promptText={this.state.promptText}
                    promptFile={this.state.promptFile}
                    language={this.state.language}
                    isSaveLocale={this.state.isSaveLocale}
                    languagesList={this.state.languagesList}
                    submitFunction={this.handleSubmitFunction}
                    handleImportFile={this.handleImportFile}
                    handleDropFile={this.handleDropFile}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isPromptsInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 promptsCardBody">
                        <CardTitle className="m-0 promptsTitle">
                            <div className="divPrompts">
                                <Badge className="titleBadgePrompts">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pPrompts.png" map={MAP} width={70} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .zip file.
                                A template .zip file can be downloaded <a style={{ color: "orangered" }} href="./templates/announcement_BulkImport_template.zip" ><u disabled>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyPrompts">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonPrompts" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleZipDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonPrompts">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".zip" onChange={this.handleZipImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage} />
                            <div className="prompts-wrap">
                                <ReactTable
                                    data={this.state.promptsList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowId] === true}
                                                        onChange={(e) => this.toggleRow(original.rowId, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.promptsList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Prompt Name",
                                            accessor: "promptName",
                                            Cell: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: "Language",
                                            accessor: "language",
                                            Cell: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowId",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Prompts loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonPrompts" onClick={this.importPrompts} disabled={this.state.promptsList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
